<template>
  <div v-if="displayOnlyTotal" v-show="canShowDash">
    <slot :currentBalance="currentBalance" name="currentBalance">
      <span v-if="currentBalance !== null">
        <slot name="currentBalanceText" v-if="hideCurrentBalanceText !== true">Balance: </slot>
        <slot name="currentBalanceValue" :currentBalance="currentBalance">{{
            currentBalance
        }}</slot>
      </span>
    </slot>
  </div>
  <div class="col-md-6 col-lg-4 col-xl-4 order-lg-1 order-xl-1" v-else-if="displayInDashboard == true"
    v-show="canShowDash">
    <!--Begin::Recent Activities-->
    <KTPortlet v-bind:title="'Recent Activities'" v-if="canShowDash" :fullContent="true">
      <!-- <template v-slot:toolbar>
            <div class="dropdown dropdown-inline">
              <button
                type="button"
                class="btn btn-clean btn-sm btn-icon btn-icon-md"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="flaticon-more-1"></i>
              </button>
              <div
                class="dropdown-menu dropdown-menu-right dropdown-menu-fit dropdown-menu-md"
              >
                <--begin::Nav-- >
                <ContextMenu1></ContextMenu1>
                <--end::Nav-- >
              </div>
            </div>
          </template> -->
      <template v-slot:body>
        <Loader v-if="payments === null && invoices === null" :newStyle="2" :hideHeader="true" :fields="['', '']">
        </Loader>
        <div v-else-if="timelines.length === 0" style="width: 100%; text-align: center">
          No recent activities
        </div>
        <Timeline2 v-else v-bind:datasrc="timelines"></Timeline2>
      </template>
    </KTPortlet>
    <!--End::Recent Activities-->
  </div>
  <div v-else-if="displayTimeline == true">
    <Loader v-if="payments === null && invoices === null" :newStyle="2" :hideHeader="true" :fields="['', '']"></Loader>
    <div v-else-if="timelines.length === 0" style="width: 100%; text-align: center">
      No recent activities
    </div>
    <Timeline2 v-else v-bind:datasrc="timelines"></Timeline2>
  </div>
  <KTPortlet v-else title="My Account Statement" :fullContent="false">
    <template slot="body">
      <Loader v-if="payments === null && invoices === null" :newStyle="2"
        :fields="['Date', 'Reference', 'Invoice', 'Payment', 'Balance', '']"></Loader>
      <div v-else-if="timelines.length === 0" style="width: 100%; text-align: center">
        No invoices/payments to display
      </div>
      <div v-else>
        <b-table-simple responsive>
          <b-thead>
            <b-tr>
              <b-th>Date</b-th>
              <b-th>Reference</b-th>
              <b-th>Invoice</b-th>
              <b-th>Payment</b-th>
              <b-th>Balance</b-th>
              <b-th></b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="item in pagedItems" v-bind:key="item.id">
              <template v-if="item.loader === true">
                <b-th>
                  <Loader :newStyle="1" />
                </b-th>
                <b-td>
                  <Loader :newStyle="1" />
                </b-td>
                <b-td>
                  <Loader :newStyle="1" />
                </b-td>
                <b-td>
                  <Loader :newStyle="1" />
                </b-td>
                <b-td>
                  <Loader :newStyle="1" />
                </b-td>
                <b-td> </b-td>
              </template>
              <template v-else>
                <b-th>
                  {{ $tools.formatDate(new Date(item.date)) }}
                  <!-- <v-chip
                  class="ma-2"
                  :style="`background: var(--${item.Status.class}); color: #fff;`"
                >
                  <v-icon left :class="item.Status.class" color="#fff">{{item.Status.icon}}</v-icon>
                  {{item.Status.text}}
                </v-chip>-->
                </b-th>
                <b-td>
                  {{ item.reference }}
                  <v-chip v-if="item.additKey === 2" class="ma-2" :style="
                    `background: var(--${ item.additKeyClass }); color: #fff;`
                  ">{{ item.additKeyName }}</v-chip>
                </b-td>

                <b-td v-if="item.invoice !== null">
                  <v-chip class="ma-2" :style="
                    `background: var(--${ item.additKey == 1
                      ? 'dark'
                      : item.invoice > 0
                        ? 'danger'
                        : 'dark'
                    }); color: #fff;`
                  ">{{
    $tools.formatCurrency(
      item.invoice,
      2,
      ".",
      " ",
      item.currencySym
    )
}}</v-chip>
                </b-td>
                <b-td v-else></b-td>

                <b-td v-if="item.payment !== null">
                  <v-chip class="ma-2" :style="
                    `background: var(--${ item.additKey == 1
                      ? 'dark'
                      : item.payment > 0
                        ? 'success'
                        : 'dark'
                    }); color: #fff;`
                  ">{{
    $tools.formatCurrency(
      item.payment,
      2,
      ".",
      " ",
      item.currencySym
    )
}}</v-chip>
                </b-td>
                <b-td v-else></b-td>

                <b-td>
                  <v-chip class="ma-2" :style="
                    `background: var(--${ item.balance === 0 || item.additKey == 1
                      ? 'primary'
                      : item.balance > 0
                        ? 'success'
                        : 'danger'
                    }); color: #fff;`
                  ">{{
    $tools.formatCurrency(
      item.balance,
      2,
      ".",
      " ",
      item.currencySym
    )
}}</v-chip>
                </b-td>
                <b-td>
                  <v-btn color="info" v-if="!$tools.isNullOrUndefined(item.actionUrl)"
                    @click="$router.push(item.actionUrl)">VIEW</v-btn>
                </b-td>
              </template>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <!-- <b-table
          :striped="true"
          :bordered="true"
          :borderless="true"
          :dark="false"
          :items="items"
          :fields="tableFields"
          head-variant="dark"
        >
          <template v-slot:cell(Actions)="row">
            <v-btn class="mx-2" fab dark small @click="showPayment(row.item.id)">
              <v-icon dark>search</v-icon>
            </v-btn>
          </template>
        </b-table>
        <b-pagination
          v-if="items.length > perPage"
          align="center"
          v-model="currentPage"
          :total-rows="items.length"
          :per-page="perPage"
        ></b-pagination>-->
      </div>
      <!-- <b-modal
        hide-footer
        size="xl"
        ref="viewInvoiceModal"
        :title="$tools.isNullOrUndefined(viewInvoice) ? 'Loading invoice' : `${viewInvoice.proforma ? 'PROF' : 'INV'}-${viewInvoice.number}: ${$tools.getStatusInfoInvoice(viewInvoice.status).text}`"
      >
        <div v-if="!$tools.isNullOrUndefined(paymentCovers)">
          <b-table
            :striped="true"
            :bordered="true"
            :borderless="true"
            :dark="false"
            :items="paymentCovers"
            :fields="invoiceTableFields"
            head-variant="dark"
          >
            <template
              v-slot:cell(Price)="row"
            >{{$tools.formatCurrency(row.item.Price, 2, '.', ' ', viewInvoice.currencyCode)}}</template>
            <template
              v-slot:cell(Total)="row"
            >{{$tools.formatCurrency(row.item.Total, 2, '.', ' ', viewInvoice.currencyCode)}}</template>
          </b-table>

          <div style="width: 100%; text-align: center;">
            <h5>Payment Logs</h5>
            <div v-for="payment in viewInvoice.paymentCovers" v-bind:key="payment.id">
              <div>
                PAY-{{payment.paymentId}}:
                <b
                  style="font-weight: 600;"
                >{{$tools.formatCurrency(payment.amount, 2, '.', ' ', viewInvoice.currencyCode)}}</b>
              </div>
            </div>
          </div>
        </div>
      </b-modal>-->
    </template>
    <template slot="foot">
      <div>
        <div v-if="payments !== null && invoices !== null && timelines.length > 0">
          <v-pagination v-if="items.length > perPage" align="center" v-model="currentPage" :total-visible="7" circle
            :length="Math.ceil(items.length / perPage)" :per-page="perPage"></v-pagination>
        </div>
      </div>
    </template>
  </KTPortlet>
</template>

<script>
import { mapGetters } from "vuex";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Loader from "@/views/partials/content/Loader.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import Timeline2 from "@/views/partials/widgets/Timeline2.vue";
//import ApiService from "@/common/api.service";
//import JWTService from "@/common/jwt.service";
import moment from "moment";

export default {
  props: ["displayTimeline", "displayInDashboard", "displayOnlyTotal", "hideCurrentBalanceText"],
  components: {
    KTPortlet,
    Loader,
    Timeline2
  },
  data() {
    return {
      lastUpdate: 0,
      currentPage: 1,
      perPage: 50,
      invoices: null,
      services: null,
      payments: null,
      paymentMethods: null,
      paymentCovers: null,
      loadingText: null,
      tableFields: ["Method", "Amount", "Created_Date"] //, "Actions"]
    };
  },
  methods: {
    getPaymentMethodForPayment(methodId) {
      for (let method of this.paymentMethods)
        if (method.id === methodId) return method;
      this.$log.error(`Unknown payment method: ${ methodId }`);
      return { name: "Paid with Magic" };
    },
    getPaymentMethods(bumpacache = false) {
      let self = this;
      let now = new Date().getTime();
      if (self.lastUpdate >= (now - 60000)) {
        self.$data.lastUpdate = now;
        return;
      }
      self.$data.lastUpdate = now;
      self.$data.loadingText = "Getting Payment Methods";
      self.$api
        .getWithCache(
          self,
          "billing",
          `${ self.$store.getters.clientId }/payments/methods`,
          "my-account:billing-payment-methods",
          bumpacache
        )
        .then(data => {
          self.$data.paymentMethods = data;
          return self.getPayments(bumpacache);
        })
        .catch(x => {
          self.$log.error(x);
          //console.error("Error loading content");
        });
    },
    getPayments(bumpacache = false) {
      let self = this;
      self.$data.loadingText = "Getting Payments";
      self.$api
        .getWithCache(
          self,
          "billing",
          `${ this.$store.getters.clientId }/payments`,
          "my-account:billing-payments",
          bumpacache
        )
        .then(data => {
          self.$data.currentPage = 1;
          self.$data.payments = data;
          self.getInvoices(bumpacache);
        })
        .catch(x => {
          self.$log.error(x);
          //console.error("Error loading content");
        });
    },
    getInvoices(bumpacache = false) {
      let self = this;
      self.$data.loadingText = "Getting Invoices";
      self.$api
        .getWithCache(
          self,
          "billing",
          `${ this.$store.getters.clientId }/invoices`,
          "my-account:billing-invoices",
          bumpacache
        )
        .then(data => {
          self.$data.invoices = data;
          self.getServices(bumpacache);
        })
        .catch(x => {
          self.$log.error(x);
          //console.error("Error loading content");
        });
    },
    getServices(/*bumpacache = false*/) {
      let self = this;
      self.$data.loadingText = "Getting Services";
      self.$api
        .getWithCache(
          self,
          "crm",
          `${ this.$store.getters.clientId }/services`,
          "crm:services"
          //bumpacache
        )
        .then(data => {
          self.$data.services = data;
          self.$emit('currentBalance', { label: self.currentBalance, value: self.currentBalance !== null ? self.items[self.items.length - 1].balance : 0 });
        })
        .catch(response => {
          self.$log.error(response);
        });
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    canShowDash() {
      return (this.layoutConfig().hideMenus || []).indexOf("My Account") < 0;
    },
    currentBalance() {
      if (
        !this.$tools.isNullOrUndefined(this.services) &&
        !this.$tools.isNullOrUndefined(this.invoices) &&
        !this.$tools.isNullOrUndefined(this.payments)
      ) {
        return this.$tools.formatCurrency(
          this.items[this.items.length - 1].balance,
          2,
          ".",
          " ",
          this.items[this.items.length - 1].currencySym
        );
      }
      return null;
    },
    timelines() {
      let arrOfTimeline = [];
      const maxItems = 10;
      let endIndex = this.items.length;
      if (endIndex === 0) return [];

      let startIndex = endIndex - maxItems;
      if (startIndex < 0) startIndex = 0;

      for (let x = startIndex; x < endIndex; x++) {
        if (this.items[x].loader === true) continue;
        arrOfTimeline.push({
          time: moment(this.items[x].date).format("DD/MM"),
          icon: `fa kt-font-${ this.items[x].payment !== null
            ? "info fa-receipt"
            : this.items[x].additKey === 1
              ? "success fa-file-invoice"
              : "danger fa-file-invoice"
            }`,
          text: `${ this.items[x].reference }`
        });
      }
      if (!this.$tools.isNullOrUndefined(this.currentBalance)) {
        arrOfTimeline.push({
          time: "NOW",
          icon: `fa fa-bookmark kt-font-${ this.items[this.items.length - 1].balance < 0 ? "danger" : "success"
            }`,
          text: `Current Balance: ${ this.currentBalance }`
        });
      }
      //{
      //"time": "10:00",
      //"icon": "fa fa-genderless kt-font-danger",
      //"text": "Lorem ipsum dolor sit amit,consectetur eiusmdd tempor<br>incididunt ut labore et dolore magna"
      //},
      return arrOfTimeline;
    },
    items() {
      const showLoaders = this.services === null;
      //if (this.services === null) return [];
      let dataToReturn = [];

      for (let row of this.invoices || []) {
        if (["draft", "void"].indexOf(row.status) >= 0) continue;

        if (!this.$tools.isNullOrUndefined(row.proformaInvoiceId)) continue;

        dataToReturn.push({
          id: `invoice-${ row.id }`,
          reference:
            row.proforma === true
              ? `Proforma Invoice (PROF-${ row.number })`
              : `Invoice (INV-${ row.number })`,
          additKey: ["paid", "processed"].indexOf(row.status) >= 0 ? 1 : 2,
          additKeyName: this.$tools.getStatusInfoInvoice(row.status).text,
          additKeyClass: this.$tools.getStatusInfoInvoice(row.status).class,
          payment: null,
          actionUrl: `/MyAccount/Invoices/${ row.id }`,
          invoice: row.total,
          currencyCode: row.currencyCode,
          currencySym: row.currencySym,
          date: new Date(row.createdDate).getTime()
        });
      }
      if (showLoaders) dataToReturn.push({ loader: true });

      for (let row of this.payments || []) {
        dataToReturn.push({
          id: `payment-${ row.id }`,
          reference: `Payment (${ this.getPaymentMethodForPayment(row.methodId).name
            })`,
          payment: Number.parseFloat(`${ row.amount }`),
          additKey: 1,
          additKeyName: null,
          additKeyClass: null,
          invoice: null,
          actionUrl: null,
          currencyCode: row.currencyCode,
          currencySym: row.currencySym,
          date: new Date(row.createdDate).getTime()
        });
      }
      if (showLoaders) dataToReturn.push({ loader: true });

      for (let i = 0; i < dataToReturn.length - 1; i++) {
        for (let j = i + 1; j < dataToReturn.length; j++) {
          if (dataToReturn[i].date > dataToReturn[j].date) {
            let temp = dataToReturn[j];
            dataToReturn[j] = dataToReturn[i];
            dataToReturn[i] = temp;
          }
        }
      }

      let currentBalance = 0;
      for (let i = 0; i < dataToReturn.length; i++) {
        if (dataToReturn[i].payment !== null)
          currentBalance += dataToReturn[i].payment;
        if (dataToReturn[i].invoice !== null)
          currentBalance -= dataToReturn[i].invoice;
        dataToReturn[i].balance = currentBalance;
      }

      for (let i = 0; i < dataToReturn.length - 1; i++) {
        for (let j = i + 1; j < dataToReturn.length; j++) {
          if (dataToReturn[i].date > dataToReturn[j].date) {
            let temp = dataToReturn[j];
            dataToReturn[j] = dataToReturn[i];
            dataToReturn[i] = temp;
          }
        }
      }
      if (showLoaders) dataToReturn.push({ loader: true });

      return dataToReturn;
    },
    pagedItems() {
      let listToReturn = [];

      let maxPage = this.perPage * this.currentPage;
      if (maxPage > this.items.length) maxPage = this.items.length;
      for (let i = this.perPage * (this.currentPage - 1); i < maxPage; i++) {
        listToReturn.push(this.items[i]);
      }

      return listToReturn;
    }
  },
  beforeDestroy() {
    this.$eventBus.off(`evu-service`);
    this.$eventBus.off(`evu-invoice`);
    this.$eventBus.off(`evu-payment`);
    //this.$eventBus.off(`cache-crm:services`);
    //this.$eventBus.off(`cache-my-account:billing-payments`);
    //this.$eventBus.off(`cache-my-account:billing-invoices`);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "My Account" },
      { title: "Billing" },
      { title: "Payments" }
    ]);
    let self = this;
    self.$eventBus.on(`evu-service`, () => {
      self.$log.info('service-trigger-statement');
      self.getPaymentMethods(true);
    });
    self.$eventBus.on(`evu-invoice`, () => {
      self.$log.info('invoice-trigger-statement');
      self.getPaymentMethods(true);
    });
    self.$eventBus.on(`evu-payment`, () => {
      self.$log.info('payment-trigger-statement');
      self.getPaymentMethods(true);
    });
    /*self.$eventBus.on(`cache-my-account:billing-invoices`, () => {
      self.$log.info('invoices-trigger-statement-cache');
      setTimeout(() =>
        self.getPaymentMethods(false)
        , 1000);
    });
    self.$eventBus.on(`cache-my-account:billing-payments`,  () => {
      self.$log.info('payments-trigger-statement-cache');
      setTimeout(() =>
        self.getPaymentMethods(false)
        , 1000);
    });
    self.$eventBus.on(`cache-my-account:billing-services`,  () => {
      self.$log.info('services-trigger-statement-cache');
      setTimeout(() =>
        self.getPaymentMethods(false)
        , 1000);
    });*/
    /*self.$eventBus.on(`cache-crm:services`, () =>
      self.getPaymentMethods(false)
    );
    self.$eventBus.on(`cache-my-account:billing-payments`, () =>
      self.getPaymentMethods(false)
    );
    self.$eventBus.on(`cache-my-account:billing-invoices`, () =>
      self.getPaymentMethods(false)
    );*/
    self.getPaymentMethods();
  }
};
</script>
